import React, {Component, useEffect, useState} from "react";
import Input from "../components/input";
import {
  checkSubscriptionPromoAvailability,
  createSubscriptionFreeTrial,
  getUserInfo,
  hasAuth,
  paymentProcessReloadDelay, verifyPromoCode
} from "../services/api";
import Login from "../services/login";
import StripeCheckout from "react-stripe-checkout";
import nyaIcon from '../../images/NYA_icon_128x128.jpg';
import spinnerFrame01 from "../../images/loading-indicator/frame_01.webp";
import warning from "../../images/not-supported/icon.webp";
import moment from "moment/moment";

const FreeTrial = (props) => {
  const [promoCode, setPromoCode] = useState('')
  const [error, setError] = useState("")
  const userPlanId = props.userData.userPlanId;
  const isFreeuser = userPlanId === 'NYA-FREE';
  const [paymentState, setPaymentState] = useState("initial");
  const [promoIsAvailable, setPromoIsAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("Loading...")
  const [codeVerified, setCodeVerified] = useState(false);
  const [signUpClickDelay, setSignUpClickDelay] = useState(800)

  useEffect(() => {
    const checkAvailability = async () => {
      const isAvailable = await checkSubscriptionPromoAvailability();
      setPromoIsAvailable(isAvailable);
      setIsLoading(false);
    }
    checkAvailability();
  }, []);

  const renderPromoCodeInput = (props) => {
    if (!hasAuth()) {
      return ;
    }
    return (
      <div className="gift-form">
        <Input
          className="input--promocode input"
          placeholder="Enter Promo Code"
          value={promoCode}
          onChange={(value) => handleInput(value)}
          name="code"
          setLoadingState = {() => {}}
        />
      </div>
    );
  }

  const handleInput = (value) => {
    setError("")
    setPromoCode(value);
    setCodeVerified(false);
  }

  const handleLogin = () => {
    window.freeTrial = () => Login("/account?screen=free-trial");
    freeTrial();

    setTimeout(() => {
      const tabs = document.querySelectorAll("div[role='navigation'] ul li");
      if (tabs.length < 2) {
        return;
      }
      const signUpTab = tabs[1];
      signUpTab?.firstChild.click();
      setSignUpClickDelay(100)
    }, signUpClickDelay)
  }

  const verifyCode = () => {
    setLoadingMessage("Verifying Code...");
    setIsLoading(true);
    verifyPromoCode({promoCode}).then(() => {
      setIsLoading(false);
      setCodeVerified(true);
      const purchaseButton = document.getElementById("purchase-button");
      purchaseButton.click();
    }).catch(() => {
      setIsLoading(false);
      setError("Promo code invalid. Please check and try again.")
    });
  }

  const handleFreeTrial = (token) => {
    const userInfo = {
      source: token.id,
      planId: 'NYA-UNLIMITEDV2-YEARLY',
      currentPlanPrice: '24.99',
      promoCode,
    };
    setIsLoading(true);
    setLoadingMessage("Please wait a moment while we activate your subscription...")
    createSubscriptionFreeTrial(userInfo).then(
      (res) => {
        setPaymentState("paymentOK");
        setIsLoading(false);
      }
    ).catch(
      (err) => {
        setPaymentState("paymentFail");
        setIsLoading(false);
      }
    );

  }

  const renderFreeTrialSubmitButton = () => {
    if (!hasAuth()) {
      return (
        <div className="confirm-purchase" onClick={handleLogin}>
          SUBSCRIBE NOW
        </div>
      );
    }


    if (!isFreeuser) {
      return <div className="confirm-purchase" onClick={() => window.displayWarningMessage('free-trial')}>
        GET NOW
      </div>
    }

    if (!promoCode) {
      return <div className="confirm-purchase" onClick={() => setError("Promo Code is required")}>
        GET NOW
      </div>
    }

    if (!codeVerified) {
      return <div className="confirm-purchase" onClick={verifyCode}>
        VERIFY PROMO CODE
      </div>
    }

    const userInfo = getUserInfo();
    const email =
      (userInfo.user_metadata && userInfo.user_metadata.customEmail) || userInfo.email;
    let planPrice = 2499;
    const plansAvailable = props.plansAvailable
    if (plansAvailable) {
      const classicPlan = plansAvailable['NYA-UNLIMITED']?.plan?.find((item) => {
        return item.plan_id === 'NYA-UNLIMITEDV2-YEARLY';
      });
      planPrice = parseInt(classicPlan.price.replace('.', '')) || 2499;
    }

    return (
      <StripeCheckout
        token={handleFreeTrial}
        stripeKey={process.env.STRIPE_KEY}
        email={email}
        billingAddress={true}
        image={nyaIcon}
        panelLabel="Subscribe now"
      >
        <div className="confirm-purchase" style={{width: "100%"}} id="purchase-button">GET NOW</div>
      </StripeCheckout>
    )
  }

  const renderError = () => {
    return error && <div className="error">{error}</div>
  }

  const renderContent = () => {
    if (isLoading) {
      return <div className="content">
        <p>{loadingMessage}</p>
        <img className="spinner loading" src={spinnerFrame01} alt="spinner" loading="lazy"/>
      </div>
    }

    if (!promoIsAvailable) {
      return <div className="content">
        <img src={warning} alt="Warning Message" width="64px" loading="lazy"/>
        <br/><br/>
        <p>This promo is not available at this time.</p>
        <div className="confirmation-buttons aligned">
          <div className="confirm-purchase" onClick={() => location.href = "/"}>
            CONTINUE
          </div>
        </div>
      </div>
    }

    if (paymentState === "paymentOK") {
      return <div className="content">
        <p>Thank you for subscribing to <span style={{fontFamily: "DinProBold"}}>NYA Classic</span> Plan.</p>
        <br/>
        <br/>
        <img className="spinner loading" src={spinnerFrame01} alt="spinner" loading="lazy"/>
        <p>Reload in {paymentProcessReloadDelay / 1000} seconds...</p>
      </div>
    }

    if (paymentState === "paymentFail") {
      return <div className="content">
        <img src={warning} alt="Warning Message" width="64px" loading="lazy"/>
        <br/><br/>
        <p>It seems like there was an error when activating your trial period.</p>
        <p>Please check the promo code you entered.</p>
        <p>Also make sure the credit card you entered is active.</p>
        <div className="confirmation-buttons aligned">
          <div className="confirm-purchase" onClick={() => setPaymentState("initial")}>
            TRY AGAIN
          </div>
        </div>
      </div>
    }

    return <div className="content">
      <div className="gift-message">
      <p>
        Enter your promotion code to obtain a 30 day free trial of NYA Classic
      </p>
      </div>
      {renderPromoCodeInput()}
      {renderError()}
      {hasAuth() ? <div className="gift-message">
        <p>You will be asked to enter your credit card information, but you will not be charged until day 31 ({moment().add(30, "days").format('LL')}).</p>
        <p>You can cancel anytime before that date and will lose access. If you decide to continue your subscription,</p>
        <p>you will be charged $24.99 for a one year subscription.</p>
      </div> : null}

      <div className="description-section">
        <div style={{marginTop: "20px"}} className="confirmation-buttons aligned">
          {renderFreeTrialSubmitButton()}
        </div>
      </div>
    </div>
  }

  return (
    <div className="claim-panel panel">
      <div className="title">One Month Free Offer</div>
      {renderContent()}
    </div>
  );
}

export default FreeTrial;
