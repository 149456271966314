import React, { Component } from 'react';
import store  from '../store';
import _ from 'lodash';
import PropTypes from 'prop-types';
import StripeCheckout from 'react-stripe-checkout';
import moment                from 'moment'
import { isUserAuthenticated, isFreeUser, isAppleSubscription } from '../user';
import { getFreeDownloadSong } from '../drawer-items';
import {
  getAllPlanBenefits,
  getPlanProperty,
  getPlanInfo,
  getBenefitsPerPlan,
  sortPlansAccordingPrice,
} from '../plans';
import spinnerFrame01 from '../../images/loading-indicator/frame_01.webp';
import nyaIcon from '../../images/NYA_icon_128x128.jpg';
import Input from '../components/input';
import Login from '../services/login';
import { registerButtonClick } from '../services/tracking';
import { fetchExclusiveEntries } from '../services/fetch';
import SplashScreen from '../splash-screen';
import checkImg from '../../images/account-info/check-item.webp';
import { NYA_FREE,NYA_UNLIMITED, NYA_UNLIMITEDV2 } from '../util/contants/url_constants';
import arrowImg from '../../images/account-info/arrow-image.webp';

import {
  getUserInfo,
  createOrUpdatePlan,
  hasAuth,
  redeemCode,
  isPaying,
  setIsPaying,
  paymentProcessReloadDelay,
  freePassToken,
  checkCustomerNextProrationInvoice,
  resetPaymentProcess,
  getGiftCode,
  redeemUpgradeCode
} from "../services/api";

// TODO: Ensure `paymentFail` returns reletive error msg

class Line extends Component {
  render() {
    return (
      <div className='description-line'>
        <div className='line'>
          <div className='line-text'> {this.props.children}</div>
          <div className='plan-checks'>{this.props.planChecks}</div>
        </div>
      </div>
    );
  }
}

class RadioButton extends Component {
  render() {
    const { id, checked, value, onChange, disabled, product } = this.props;
    return (
      <div className='custom-radio'>
        <input
          type='radio'
          id={id}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          value={value}
          name={value}
        />
        <div className='toggle' />
      </div>
    );
  }
}

class CheckBox extends Component {
  render() {
    const { checked, onChange, name } = this.props;
    return (
      <div className='custom-checkbox'>
        <input
          type='checkbox'
          checked={checked}
          onChange={onChange}
          name={name}
        />
        <div className='image' />
      </div>
    );
  }
}

/*
   initial -> select -> paying -> paymentOK
                          +-----> paymentFail -> initial
*/

export default class PlansPanel extends Component {
  constructor(props, ctx) {
    super(props, ctx);
    //previous const state = props.view === "select" ? "select" : "initial";
    const state =
      props.view === "select"
        ? "select"
        : props.view === "confirmation"
        ? "confirmation"
        : "initial";
    const queryCode = props.router.location.query.code;
    this.prorationRequestFinished = false;
    this.state = {
      state,
      annualPlan: "year",
      acceptedTerms: false,
      giftcode: queryCode || "",
      tryOtherPlan: false,
      codeNotification: "",
    };
    this.planNames = {
      "NYA-UNLIMITED": "CLASSIC",
      "NYA-RUST": "RUST",
      "NYA-UNLIMITEDV2": "CLASSIC",
      "NYA-RUSTV2": "RUSTV2",
      "NYA-PATRON": "PATRON",
    };

    this.initialClickPurchase = this.initialClickPurchase.bind(this);
    this.planSelectionOnChange = this.planSelectionOnChange.bind(this);
    this.acceptedTermsOnChange = this.acceptedTermsOnChange.bind(this);
    this.selectGotToken = this.selectGotToken.bind(this);
    this.clickExplore = this.clickExplore.bind(this);
    this.backToPlans = this.backToPlans.bind(this);
    this.giftcodeOnChange = this.giftcodeOnChange.bind(this);
    this.sendCodeHandler = this.sendCodeHandler.bind(this);
    this.triggerAuth0 = this.triggerAuth0.bind(this);
    this.triggerConversionEvent = this.triggerConversionEvent.bind(this);
    this.goToSelectionScreen = this.goToSelectionScreen.bind(this);
    this.getStartingBalance = this.getStartingBalance.bind(this);
    this.handlePurchaseGiftClick = this.handlePurchaseGiftClick.bind(this);
    this.handleClaimGiftClick = this.handleClaimGiftClick.bind(this);
  }
  componentDidMount() {
    if (this.props.view === "select") this.triggerAuth0();
    let { plansAvailable } = this.props;
    let planSelected = plansAvailable && (plansAvailable[NYA_UNLIMITED] || plansAvailable[NYA_UNLIMITEDV2]);
    if (planSelected) {
      this.setState({
        planSelected,
        planId: planSelected === plansAvailable[NYA_UNLIMITED] ? NYA_UNLIMITED : NYA_UNLIMITEDV2,
        singleRadioButtonRendered: false,
        setAnualRadioButtonData: false,
      });
    }
  }
  componentWillUpdate(newprops /*, newstate*/) {
    if (newprops.view !== this.props.view) {
      let state;
      let singleRadioButtonRendered = false;
      let setAnualRadioButtonData = false;
      if (newprops.view === "select") state = "select";
      if (newprops.view === "confirmation") state = "confirmation";
      if (newprops.view === undefined) state = "initial";
      if (state)
        this.setState({
          state,
          singleRadioButtonRendered,
          setAnualRadioButtonData,
          tryOtherPlan: false,
          acceptedTerms: false,
          giftcode:""
        });
    }

    if (newprops.view === "select") this.triggerAuth0();
  }
  componentWillUnmount() {
    clearTimeout(this.emit);
  }
  triggerConversionEvent() {
    const { planSelected } = this.state;
    const currency = "USD";
    const value = parseFloat(planSelected.price);

    registerButtonClick({ value, currency });
  }
  fetch() {
    fetchExclusiveEntries().catch(() => {
      document.location.href = "/";
      document.location.reload();
    });
  }

  triggerAuth0() {
    if (!hasAuth()) {
      clearTimeout(this.emit);
      this.emit = setTimeout(Login, 100);
    }
  }
  getContent() {
    return this[this.state.state]();
  }

  handlePurchaseGiftClick() {
    if (!isUserAuthenticated()) {
      Login("/account", "/account?screen=gift");
    } else {
      document.location.href = "/account?screen=gift";
    }
  }

  handleClaimGiftClick() {
    if (!isUserAuthenticated()) {
      Login("/account", "/account?screen=claim-gift");
    } else {
      document.location.href = "/account?screen=claim-gift";
    }
  }
  renderPlanBenefits(planBenefitsObj, plansData, planIDs) {
    const planBenefits = planBenefitsObj && planBenefitsObj.planBenefit;
    const benefitsArray = planBenefits && getAllPlanBenefits(planBenefits); //array de aobjetos {benefitid, benfitText}
    const benefitsPerPlan = getBenefitsPerPlan(plansData);
    let checkUI = [];
    return (
      <div className="details section">
        {benefitsArray &&
          benefitsArray.map((benefit, idx) => {
            checkUI = this.checkIfBenefitOnPlan(
              benefit.benefitID,
              planIDs,
              benefitsPerPlan,
              benefit.customCheckImages
            );
            return (
              <div key={idx}>
                <Line key={idx} planChecks={checkUI}>
                  {benefit.benefitText}
                </Line>
              </div>
            );
          })}
      </div>
    );
  }

  checkIfBenefitOnPlan(benefit, planIDs, plansData, customCheckImages) {
    let checkItemsUI = [];
    let customStyle = customCheckImages ? "custom" : "";
    planIDs.forEach((planID) => {
      let includedBenefit =
        plansData[planID] && plansData[planID].includes(benefit);
      let planIndex = planIDs.indexOf(planID) + 1;
      if (includedBenefit) {
        checkItemsUI.push(
          <div
            className={`check  level-${planIndex} ${customStyle} `}
            key={`plan-${planIndex}`}
          >
            <img
              src={
                customCheckImages
                  ? this.getCustomCheckURL(customCheckImages, planID)
                  : checkImg
              }
              loading="lazy"
            />
          </div>
        );
      }
    });
    return checkItemsUI;
  }

  getCustomCheckURL(customCheckImages, planID) {
    let checkURL = checkImg;
    customCheckImages[planID] && (checkURL = customCheckImages[planID]);
    return checkURL;
  }
  planType(planId) {
    const plan = {
      "NYA-UNLIMITED": "free",
      "NYA-UNLIMITEDV2": "free",
      "NYA-RUSTV2": "rust",
      "NYA-RUST": "rust",
      "NYA-PATRON": "patron",
    };
    return plan[planId];
  }

  renderPlans(plans, planInformation) {
    const { planType: userplanId = NYA_FREE } = this.props.purchasedPlan;
    const userAuthenticated = isUserAuthenticated();
    const isAppleSub = isAppleSubscription();
    const hasExpired = isFreeUser();
    const showBtns  = !isAppleSub ? true : (hasExpired && isAppleSub);

    const planItems = [];
    let plansData = [];
    let planIDs = [];
    //Deleting NYA-FREE --> TEMPORAL, SHOULD BE DELETED FROM DATA BASE INSTEAD
    if (plans[NYA_FREE]) {
      delete plans[NYA_FREE];
    }
    let sortedPlans = sortPlansAccordingPrice(plans);
    const bottomButtons = [];
    Object.keys(sortedPlans).forEach((planId) => {
      let userHasThisPlan = userplanId.includes(planId);
      let plan = plans[planId];
      let isFreePlan = planId == NYA_FREE;
      let planStyle = this.planType(planId);
      let prices = plan["prices"];
      let planInfo =
        (planInformation.data && getPlanInfo(planInformation.data, planId)) ||
        {};
      plansData.push(planInfo);
      planIDs.push(planId);
      //ITERATE TO CREATE PLAN ITEM
      planItems.push(
        <div className={`pane  ${planStyle}`} key={`plan-${planId}`}>
          <div className="header section">
            <img className="header-image" src={planInfo.headerURL} loading="lazy"/>
            <p className="plan-title">{planInfo.displayName}</p>
          </div>
          <div className="description section plan-description">
            {prices}
            <span className="extra-info">
            {planId === NYA_UNLIMITED || planId === NYA_UNLIMITEDV2 ? 'Originally called "Unlimited"' : ''}
            </span>
            <p className={`description-text  ${planStyle}`}>
              {planInfo.planDescription}
            </p>
            {showBtns &&
              this.renderGetPlanButtons(
                isFreePlan,
                userHasThisPlan,
                userAuthenticated,
                plan,
                planId
              )}
          </div>
        </div>
      );
      if (showBtns)
        bottomButtons.push(
          this.renderGetPlanButtons(
            isFreePlan,
            userHasThisPlan,
            userAuthenticated,
            plan,
            planId,
            false
          )
        );
    });

    return (
      <div className="content plansContainer">
          <div className="gift-buttons-container">
            <div className="gift-cards-label">Gift Cards</div>
            <button onClick={this.handlePurchaseGiftClick} className="gift-button button get-plan">PURCHASE GIFTCARD</button>
            <button onClick={this.handleClaimGiftClick} className="gift-button button get-plan">CLAIM GIFTCARD</button>
          </div>
          <div className="content initial">{planItems}</div>
          {this.renderPlanBenefits(planInformation.data, plansData, planIDs)}
          <div className="bottom-buttons">{bottomButtons}</div>
      </div>
    );
  }


  renderGetPlanButtons(
    isFreePlan,
    userHasThisPlan,
    userAuthenticated,
    plan,
    planId,
    topButton = true
  ) {
    const {userIsFree} = this.props.userData
    const { disableSuscriptionsPurchase } = store.getState().commonValues
    let buttonText = getPlanProperty("buyButtonText", planId);
    buttonText = typeof buttonText === "string" ? buttonText : planId;
    const isFreeTrial = this?.props?.userData?.isSubscriptionTrial;
    const disableButton = !userHasThisPlan && isFreeTrial;

    return (
      <div
        className={`button-bottom ${
          topButton ? "plan-button" : "plan-button-bottom"
        }`}
      >
        {!isFreePlan && (
          <button
            hint={disableButton ? "Plan upgrades are not available during your free trial. You can upgrade after the trial period ends." : ""}
            className={`button get-plan ${
              userHasThisPlan &&  !userIsFree  && userAuthenticated
                ? "subscribed"
                : this.checkIfShouldHideButton(planId)
            } ${disableButton ? 'disabled': ''}`}
            disabled={disableButton}
            onClick={() => (!userHasThisPlan && disableSuscriptionsPurchase) ? window.disableSubscriptionsPurchase() : this.initialClickPurchase(plan, planId)}
          >
            {userHasThisPlan &&  !userIsFree && userAuthenticated ? "MANAGE" : buttonText}
          </button>
        )}
      </div>
    );
  }

  checkIfShouldHideButton(planID) {
    const { planType } = this.props.purchasedPlan;
    let currentPlanName = this.planNames[planType] || "NYA-FREE";
    let buttonPlanName = this.planNames[planID];
    const userAuthenticated = isUserAuthenticated();
    let className = "";
    if (userAuthenticated) {
      if (
        (currentPlanName == "RUSTV2" && buttonPlanName == "CLASSIC") ||
        (currentPlanName == "PATRON" &&
          (buttonPlanName == "CLASSIC" || buttonPlanName == "RUSTV2"))
      ) {
        className = "hidden";
      }
    }
    return className;
  }

  initial() {
    const { plansAvailable, planInformation } = this.props;
    /*
            !auth()?        -> Display all plans
            NYA -Unlimited ? -> Display only Rust and patreon
            NYA -RUST     ? -> Display only patreon and NYA-unlimited
            NYA -Patron   ? -> Display only Rust and NYA-unlimited

        */
    if (_.isEmpty(plansAvailable)) {
      return (
        <div className="content initial">
          <SplashScreen loadState={100} />
        </div>
      );
    } else {
      return <div>{this.renderPlans(plansAvailable, planInformation)}</div>;
    }
  }

  initialClickPurchase(plan, planId) {
    let planToBePurchased = plan && plan.plan[0];
    const {userIsFree}  = this.props.userData;
    const { planType, name } = this.props.purchasedPlan;

    this.setState({
      planSelected: plan,
      planId,
      planToBePurchased,
      annualPlan: planToBePurchased.interval,
      productId: planToBePurchased.product_id,
    });
    // Verify that the plan is different than tha one purchased to enable upgrade/downgrade
    if (planType !== planId || userIsFree) {
      // show other view other than view
      if (hasAuth()) {
        if (planId !== "NYA-UNLIMITEDV2" && planId !== "NYA-UNLIMITED") {
          if (name === "NYA-FREE") {
            this.props.router.replace({
              pathname: "/account",
              query: { screen: "plans", view: "select" },
            });
          } else {
            this.props.router.replace({
              pathname: "/account",
              query: { screen: "plans", view: "confirmation" },
            });
          }
        } else {
          if (name !== "NYA-FREE") {
            this.props.router.replace({
              pathname: "/account",
              query: { screen: "plans", view: "confirmation" },
            });
          } else {
            this.props.router.replace({
              pathname: "/account",
              query: { screen: "plans", view: "select" },
            });
          }
        }
      } else {
        Login("/account?screen=plans&view=select");
      }
    } else {
      this.props.router.replace({
        pathname: "/account",
        query: { screen: "subscription" },
      });
    }
  }
  giftUpgrade(code, creditPrice) {
    const { upgrade, productId, planToBePurchased } = this.state;
    const { planType } = this.props.purchasedPlan;
    const { price: displayPrice } = planToBePurchased;

    const { price: currentPlanPrice } = planToBePurchased;

    let planBeforeUpgrade = this.planNames[planType] || "";
    const upgradeInfo = {
      planId: productId,
      upgrade,
      currentPlanPrice,
      planBeforeUpgrade,
      giftcodeUpgrade: true,
      codeUpgradeId: code,
      creditPrice,
    };

    createOrUpdatePlan(upgradeInfo).then(
      (res) => {
        redeemUpgradeCode(code);
        this.setState({ state: "paymentOK" });
      },
      (err) => this.setState({ state: "paymentFail" })
    );
  }

  sendCodeHandler() {
    const { giftcode, planToBePurchased, upgrade, creditPrice } = this.state;
    const { isGift = false } = this.props.userData;

    if(giftcode.length < 8){
      this.setState({
        tryOtherPlan: true,
        codeNotification: "Invalid Code",
      })
      return;
    }
    getGiftCode(giftcode)
      .then((giftcodeInfo, error) => {
        const { planId, code , status} = giftcodeInfo;

        if (isGift && !code) {
          this.setState({
            state: "paying",
            codeNotification:
              "Cannot redeem your giftcode. Please contact us or make purchase without gift.",
            contactBtn: true,
          });
          return;
        }
        if (planId === planToBePurchased.product_id) {
          this.setState(
            { state: "paying", giftcodeInfo, giftcodeUpgrade: true },
            () => {
              if (upgrade && !isGift) {
                this.setState({ codeNotification: "" });
                this.giftUpgrade(code, creditPrice);
                return;
              }
              redeemCode(this.state.giftcode).then(
                (res) => this.setState({ state: "paymentOK" }),
                (err) => {
                  console.log("err", err)
                  this.setState({
                    state: "paymentFail",
                    codeNotification:
                      "Gift redemption failed, please contact us",
                  });
                }
              );
            }
          );
        } else {
          this.setState({
            tryOtherPlan: true,
            couponPlan: planId,
            codeNotification: `Redeem your coupon on plan ${planId}`,
          });
        }
      })
      .catch((err) => {
        const {codeError = false} = err || {}
        const codeNotification = codeError ? codeError :"Giftcode already redeemed. If there has been a mistake please contact us."
        this.setState({
          tryOtherPlan: true,
          codeNotification,
        });
      });
  }

  _getPurchaseButton() {
    const { acceptedTerms, giftcode, planToBePurchased, chargesPreview } =
      this.state;
    const { prorationCredit, userSubType, userSubscriptionStatus } = this.props.userData;
    const needChangePaymentMethod = (userSubscriptionStatus === 'declined-active' && userSubType === 'stripe')

    let displayPrice = "";
    if (chargesPreview) {
      displayPrice = this.getCopyPrice();
    }

    let planPrice =
      displayPrice != ""
        ? displayPrice.replace(/[ ,.]/g, "")
        : planToBePurchased && planToBePurchased.price.replace(/[ ,.]/g, "");

    if (prorationCredit) planPrice = parseFloat(planPrice - prorationCredit);

    if (isPaying()) {
      return null;
    }
    if (acceptedTerms) {
      if (giftcode !== "") {
        return (
          <div className="button" onClick={this.sendCodeHandler}>
            SUBMIT
          </div>
        );
      } else if(needChangePaymentMethod) {
        return <div className="button disabled">SUBMIT</div>;
      } else {
        const price = parseInt(planPrice);
        const userInfo = getUserInfo();
        const email =
          (userInfo.user_metadata && userInfo.user_metadata.customEmail) ||
          userInfo.email;

        return (
          <StripeCheckout
            token={this.selectGotToken}
            stripeKey={process.env.STRIPE_KEY}
            email={email}
            billingAddress={true}
            amount={price}
            image={nyaIcon}
          >
            <div className="button">SUBMIT</div>
          </StripeCheckout>
        );
      }
    } else {
      return <div className="button disabled">SUBMIT</div>;
    }
  }

  planSelectionOnChange(product) {
    const { interval, product_id } = product;

    this.setState({
      annualPlan: interval,
      planToBePurchased: product,
      productId: product_id,
    });
  }

  giftcodeOnChange(value) {
    const { state } = this.state;
    const { isGift } = this.props.userData;
    let creditPrice = this.getStartingBalance();
    let tryOtherPlan = false;
    let codeNotification = "";
    let upgradeMessage = false;

    if ((value.length === 8) && (state == "confirmation" || state == "select") && !isGift && creditPrice) {
      codeNotification = `Today you will be charged $0.00 and a credit of $${creditPrice}  will be applied to your next renewal/upgrade (this is the amount remaining on your current subscription).`;
      tryOtherPlan = true;
      upgradeMessage = true;
    }
    const giftcode = value;
    this.setState({
      giftcode,
      annualPlan: "year",
      creditPrice,
      codeNotification,
      tryOtherPlan,
      upgradeMessage,
    });
  }

  acceptedTermsOnChange() {
    this.setState({ acceptedTerms: !this.state.acceptedTerms });
  }

  getRadioButtonConfig(product) {
    const { interval, price } = product;
    const { annualPlan } = this.state;
    let preSaleTickets = true;
    let productPosition = "left";
    let productIntervalOption = "monthlyOption";
    let giftcodeDisplay = false;
    if (interval === "year") {
      // TODO REMOVE GIFTCODE FOR OTHER PLANS
      productPosition = "right";
      productIntervalOption = "annualOption";
      giftcodeDisplay = true;
    }
    let config = {
      interval,
      price,
      productPosition,
      productIntervalOption,
      giftcodeDisplay,
      preSaleTickets,
      product,
      annualPlan,
    };
    return config;
  }

  renderRadioButton(config, automaticallyChecked) {
    const {
      interval,
      price,
      productPosition,
      productIntervalOption,
      giftcodeDisplay,
      preSaleTickets,
      product,
      annualPlan,
    } = config;
    //This is necessary for set yearly option as default whenever montly option is available too.
    if (
      !automaticallyChecked &&
      interval == "year" &&
      !this.state.setAnualRadioButtonData
    ) {
      this.setState({
        annualPlan: interval,
        planToBePurchased: product,
        productId: product.product_id,
        setAnualRadioButtonData: true,
      });
    }

    return (
      <div
        className={`radio-${productPosition}`}
        key={`radio-${productPosition}`}
        onClick={() => this.planSelectionOnChange(product)}
      >
        <RadioButton
          id={productIntervalOption}
          checked={
            automaticallyChecked ? automaticallyChecked : annualPlan == interval
          }
          onChange={() => this.planSelectionOnChange(product)}
          product={product}
          value={interval}
        />
        <div className="radio-description">
          <div className="term">
            {interval == "month" ? "Monthly" : "Annual"}
          </div>
          <div className="price">
            ${price} per {interval}
            <br />
            {preSaleTickets && (
              <span>
                {" "}
                {interval == "month"
                  ? "(no pre-sale ticket access)"
                  : "(includes pre-sale ticket access)"}
              </span>
            )}
          </div>
          {giftcodeDisplay && (
            <Input
              className="input--giftcode input"
              placeholder="Enter Gift Code"
              limit={8}
              value={this.state.giftcode}
              onChange={this.giftcodeOnChange}
              name="code"
            />
          )}
        </div>
      </div>
    );
  }

  productSelection() {
    const productSelection = [];
    const { planSelected, planId } = this.state;
    const { name } = this.props.purchasedPlan;

    // Define the desired prices for each plan and interval
    const desiredPrices = {
      "NYA-UNLIMITED": {
        month: "2.99",
        year: "24.99",
      },
      "NYA-RUST": {
        year: "44.99",
      },
      "NYA-PATRON": {
        year: "99.99",
      },
    };

    if (planSelected) {
      planSelected.plan.forEach((product) => {
        const { interval } = product;
        let config = this.getRadioButtonConfig(product);
        let shouldShow = false;

        // Get the desired price for the corresponding plan and interval
        const desiredPlanPrices = desiredPrices[planId];
        const desiredPrice = desiredPlanPrices ? desiredPlanPrices[interval] : undefined;

        // Compare the config.price with the desired price
        if (desiredPrice && config.price === desiredPrice) {
          shouldShow = true;
        }

        if (shouldShow) {
          productSelection.push(this.renderRadioButton(config, false));
        }
      });
    }

    return <div>{productSelection}</div>;
  }



  renderSingleAnualRadioButton() {
    const { planSelected } = this.state;
    let plan = planSelected.plan.find((plan) => plan.interval == "year");
    const { interval, product_id } = plan;
    let config = this.getRadioButtonConfig(plan);
    let anualRadioButton = this.renderRadioButton(config, true);

    if (!this.state.singleRadioButtonRendered) {
      this.setState({
        annualPlan: interval,
        planToBePurchased: plan,
        productId: product_id,
        singleRadioButtonRendered: true,
      });
    }
    return anualRadioButton;
  }

  finishedProrationRequest() {
    this.prorationRequestFinished = true;
    this.setState({ prorationAvailable: true });
  }

  checkFutureProration(planSelected) {
    //Supporting proration preview of an anual subscription
    let chargesPreview = [];
    let plan = planSelected.plan.find((plan) => plan.interval == "year");
    this.prorationRequestFinished = false;
    const { product_id, interval } = plan;

    checkCustomerNextProrationInvoice(product_id)
      .then((nextInvoice) => {
        const {
          starting_balance = false,
          current_plan_amount = false,
          lines: { data = {} },
        } = nextInvoice;
        const [prevInvoice] = data;

        const amount = (prevInvoice && prevInvoice.amount) || 0;
        const startingBalance = starting_balance
          ? starting_balance
          : amount !== 0
          ? amount
          : false;
        chargesPreview.push({
          interval,
          futureCharge: nextInvoice.amount_due,
          startingBalance,
          current_plan_amount,
          prorationDate: nextInvoice.subscription_proration_date,
        });
        this.setState({
          chargesPreview,
          prorationPreviewPlan: planSelected.planName,
          defaultProration: false,
        });
        this.finishedProrationRequest();
      })
      .catch((err) => {
        chargesPreview.push({
          interval: "year",
          futureCharge: plan.price,
        });
        this.setState({
          chargesPreview,
          prorationPreviewPlan: planSelected.planName,
          prorationAvailable: true,
          defaultProration: true,
        });
        this.finishedProrationRequest();
        console.error(
          "There was an error obtaining the proration charge" + err
        );
      });
  }

  checkIfUpgradeOrDowngrade(currentPlanName, newPlanName) {
    let action = "upgrade";
    if (
      (currentPlanName == "RUSTV2" && newPlanName == "CLASSIC") ||
      (currentPlanName == "PATRON" &&
        (newPlanName == "CLASSIC" || newPlanName == "RUSTV2"))
    ) {
      action = "downgrade";
    }
    return action;
  }

  getCopyPrice() {
    const { chargesPreview, defaultProration } = this.state;
    let price = chargesPreview[0].futureCharge;
    if (!defaultProration) {
      price = parseFloat(chargesPreview[0].futureCharge / 100).toFixed(2);
    }
    return price;
  }

  getDefaultDate() {
    var currentDate = new Date();
    var year = currentDate.getFullYear() + 1;
    var month = currentDate.getMonth() + 1;
    var day = currentDate.getDate();
    var finalDate = `${month}/${day}/${year}`;
    return finalDate;
  }

  renderConfirmationContent(displayDate, displayPrice) {
    const { creditPrice, upgradeMessage, giftcode } = this.state;
    const {isGift} = this.props.userData

    if (((creditPrice == false) && (giftcode !== ""))  || ((giftcode !== "") && creditPrice && isGift)) {
      return (
        <div className="explanation">
          Can't redeem your giftcode. Please Contact Us.
        </div>
      );
    } else if (giftcode && upgradeMessage && creditPrice !== false) {
      let date  = moment().add(1, 'years').format("MM/DD/YYYY")

      return (
        <div className="explanation">
          Today you will be charged $0.00 and a credit of{" "}
          <p className="proration-value">{`$${creditPrice} `}</p>
          will be applied to your next renewal
          <br />
          (this is the amount remaining on your current subscription).
          <br />
          <br />
          Unless cancelled, your subscription will renew on{" "}
          <p className="renewal-date">{`${date} (one year from today).`}</p>.
        </div>
      );
    } else {
      return (
        <div className="explanation">
          Today you will be charged{" "}
          <p className="proration-value">{`$${displayPrice}`}</p>
          <br />
          (This is the pro-rated cost of your new subscription for the remainder of the year term less the amount left on your current subscription.)
          <br />
          <br />
          Your subscription renewal date will stay the same. Unless canceled,
          your new subscription will renew at the full price on <p className="renewal-date">{displayDate}</p>.
        </div>
      );
    }
  }
  renderConfirmationCopy(action, currentPlanName, newPlanName) {
    const {
      chargesPreview,
    } = this.state;
    const { prorationCredit } = this.props.userData;
    const { renewalDate, interval } = this.props.purchasedPlan;
    let displayDate = interval == "month" ? this.getDefaultDate() : renewalDate;
    let displayPrice = 0.0;
    if (chargesPreview) displayPrice = this.getCopyPrice();
    if (prorationCredit)
      displayPrice = parseFloat(displayPrice - prorationCredit);

    if (action == "downgrade") {
      return (
        <div className="confirmation-description">
          <div className="confirmation-title"> What you need to know:</div>
          <div className="explanation">
            Your current{" "}
            <div className="plan-name">{currentPlanName.toLowerCase()}</div>{" "}
            subscription will remain active for the remainder of it's current
            term.
            <br />
            <br />
            At the end of this term, on{" "}
            <p className="renewal-date">{renewalDate}</p> your account will be
            downgraded to a{" "}
            <div className="plan-name">{newPlanName.toLowerCase()}</div>{" "}
            subscription and you will be charged{" "}
            <p className="proration-value">{`$${displayPrice}`}</p>
            <br />
            <br />
            You will continue to enjoy the benefits of your current subscription
            level until that time.
          </div>
        </div>
      );
    } else {
      return (
        <div className="confirmation-description">
          <Input
            className="input--giftcode input"
            placeholder="Enter Gift Code"
            limit={8}
            value={this.state.giftcode}
            onChange={this.giftcodeOnChange}
            name="code"
          />

          <div className="confirmation-title"> What you need to know:</div>
          {this.renderConfirmationContent(displayDate, displayPrice)}
        </div>
      );
    }
  }

  renderConfirmationButtons(action) {
    let buttonText = "UPGRADE SUBSCRIPTION";
    if (action == "downgrade") buttonText = "DOWNGRADE SUBSCRIPTION";
    return (
      <div className="confirmation-buttons">
        <div className="confirm-purchase" onClick={this.goToSelectionScreen}>
          {buttonText}
        </div>
        <div className="back-purchase" onClick={this.backToPlans}>
          BACK
        </div>
      </div>
    );
  }

  goToSelectionScreen() {
    this.setState({ upgrade: true, prorationAvailable: false });
    this.props.router.replace({
      pathname: "/account",
      query: { screen: "plans", view: "select" },
    });
  }

  renderConfirmationScreen() {
    const { planSelected, planId } = this.state;
    const {
      planType,
      interval,
      price: currentPlanPrice,
    } = this.props.purchasedPlan;

    let currentPlanName = this.planNames[planType] || "NYA-FREE";
    let newPlanName = this.planNames[planId];
    let action = this.checkIfUpgradeOrDowngrade(currentPlanName, newPlanName);

    return (
      <div className="content select confirmation">
        <div className="header-section">
          <div className="plan-box current">
            <div className="plan-status">Current Subscription</div>
            <div className="plan-name">
              {currentPlanName ? currentPlanName : "NYA-FREE"}
            </div>
            <div className="plan-price">
              {currentPlanPrice && interval
                ? `$${currentPlanPrice} /${interval}`
                : ""}
            </div>
          </div>
          <img className="arrow" src={arrowImg} loading="lazy"/>
          <div className="plan-box new">
            <div className="plan-status">New Subscription</div>
            <div className="plan-name">{newPlanName}</div>
            <div className="plan-price">{`$${planSelected.yearPrice} /year`}</div>
          </div>
        </div>
        <div className="description-section">
          {this.renderConfirmationCopy(action, currentPlanName, newPlanName)}
          {this.renderConfirmationButtons(action)}
        </div>
      </div>
    );
  }

  confirmation() {
    const { planSelected, prorationPreviewPlan, prorationAvailable } =
      this.state;
    if (!prorationAvailable) {
      planSelected &&
        prorationPreviewPlan != planSelected.planName &&
        this.checkFutureProration(planSelected);
    }

    if (!planSelected) {
      this.setState({ state: "initial" });
      return null;
    }

    if (this.state.prorationAvailable && this.prorationRequestFinished) {
      let confirmationScreen = this.renderConfirmationScreen();
      // this.state.prorationAvailable = false; // FIXME
      // this.prorationRequestFinished = false;
      this.state.prorationPreviewPlan = "";
      return confirmationScreen;
    } else {
      return (
        <div className="content select confirmation">
          <SplashScreen loadState={100} />
        </div>
      );
    }
  }

  select() {
    const {
      acceptedTerms,
      giftcode,
      upgrade,
      planSelected,
      planId,
      tryOtherPlan,
      codeNotification,
    } = this.state;

    const { name } = this.props.purchasedPlan;

    const { userSubType, userSubscriptionStatus } = this.props.userData;
    const needChangePaymentMethod = (userSubscriptionStatus === 'declined-active' && userSubType === 'stripe' && !giftcode && upgrade)

    let needSubscriptionPurchase = true;
    const token = freePassToken();
    const hasValidFreePass = token !== null && token.validity ? true : false;
    let newPlanName = this.planNames[planId];
    //TEMP DISABLE TO ALLOW UPGRADE -DOWNGRADE
    // if (hasAuth() && !hasValidFreePass) needSubscriptionPurchase = isFreeUser();
    if ((!needSubscriptionPurchase && !giftcode) || !planSelected) {
      this.setState({ state: "initial" });
      return null;
    }

    return (
      <div className="content select">
        {needSubscriptionPurchase ? (
          <React.Fragment>
            <div className="select-header">
              {planId != "NYA-UNLIMITEDV2"
                ? `${newPlanName} Subscription`
                : name != "NYA-FREE"
                ? `${newPlanName} Subscription`
                : "Select Subscription"}
            </div>
            {this.productSelection()}
            {tryOtherPlan && (
              <div className="coupon-redeem" onClick={this.backToPlans}>
                {codeNotification}
              </div>
            )}
            <div className="terms">
              <CheckBox
                checked={acceptedTerms}
                onChange={this.acceptedTermsOnChange}
                name="terms"
              />
              <div className="terms-blurb">
                By clicking submit, you authorize us to charge you the price
                above and applicable tax for the duration that you have
                selected. After this period ends, your subscription will
                automatically be renewed and you will be charged the standard
                price for that subscription until you cancel.{" "}
                {!(giftcode && upgrade) &&
                  "This does not apply to gift subscriptions which will expire 1 year to the date after redemption."}
                You can see when you subscription will renew next, or learn how
                to cancel, via your account page. Your subscription is subject
                to our{" "}
                <a href="/terms.html" target="_blank">
                  Terms
                </a>{" "}
                and to our{" "}
                <a href="/privacy.html" target="_blank">
                  Privacy Policy
                </a>

                {needChangePaymentMethod ?
                    (" Sorry, but it looks like your last payment failed, please update your payment method before upgrating")
                : null}

              </div>
            </div>
            <div className="buttons-bottom">{this._getPurchaseButton()}</div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="message subscribed">
              Sorry, but it looks like you're trying to claim a gift but you
              already have a subscription
            </div>
            <div className="buttons-bottom">
              <div className="button clear" onClick={this.clickExplore}>
                EXPLORE NYA
              </div>
              <div style={{ height: "65px" }} />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  getStartingBalance() {
    const { chargesPreview } = this.state;

    const startingBalance =
      chargesPreview && chargesPreview[0]
        ? chargesPreview[0].startingBalance
        : false;
    let creditPrice = startingBalance ? startingBalance * -1 : false;
    if (creditPrice) creditPrice = parseFloat(creditPrice / 100).toFixed(2);
    return creditPrice;
  }
  selectGotToken(token) {
    const { upgrade, productId, chargesPreview, planToBePurchased } =
      this.state;
    const { price: currentPlanPrice } = planToBePurchased;
    const { isGift } = this.props.userData;

    let creditPrice = isGift ? false : this.getStartingBalance();

    const { planType } = this.props.purchasedPlan;
    let displayPrice = 0;
    if (chargesPreview) {
      displayPrice = this.getCopyPrice();
    }

    let planBeforeUpgrade = this.planNames[planType] || "";
    this.setState({ state: "paying" }, () => {
      const upgradeInfo = {
        source: token.id,
        planId: productId,
        proration: upgrade,
        upgrade,
        displayPrice,
        planBeforeUpgrade,
        beforePlanCredit: creditPrice,
        currentPlanPrice,
      };

      createOrUpdatePlan(upgradeInfo).then(
        (res) => this.setState({ state: "paymentOK" }),
        (err) => this.setState({ state: "paymentFail" })
      );
    });
  }

  paying() {
    const { codeNotification, contactBtn } = this.state;
    // const message =
    setIsPaying();

    return (
      <div className="content">
        <div className="message">
          {contactBtn ? codeNotification : "Creating your subscription..."}
        </div>
        {contactBtn && (
          <div className="buttons-bottom">
            <div
              className="button"
              onClick={() => {
                resetPaymentProcess();
                this.props.router.push(`/contact`);
              }}
            >
              CONTACT US
            </div>
            <div
              className="button clear"
              onClick={this.backToPlans}
              style={{ marginLeft: "10px" }}
            >
              BACK TO PLANS
            </div>
          </div>
        )}
      </div>
    );
  }

  paymentOK() {
    const { router } = this.context;
    let freeSongs = getFreeDownloadSong();
    this.triggerConversionEvent();
    window.history.replaceState({}, "", "/account?screen=overview");

    return (
      <div className="content">
        <div className="message">
          Thank you for purchasing a NYA subscription. <br />
          Reload in {paymentProcessReloadDelay / 1000} seconds...
          <img className="spinner" src={spinnerFrame01} alt="spinner" loading="lazy"/>
        </div>

        {freeSongs && !this.state.giftcode && (
          <div className="buttons-bottom-150">
            <div
              className="button clear"
              onClick={() => {
                router.push(`/info-card?track=${freeSongs[0].id}`);
              }}
            >
              FREE TRACK
            </div>
          </div>
        )}
        <div className="buttons-bottom">
          <div className="button" onClick={this.clickExplore}>
            EXPLORE NYA
          </div>
        </div>
      </div>
    );
  }

  paymentFail() {
    return (
      <div className="content">
        <div className="message">
          Sorry. Your payment information didn't go through.
        </div>
        <div className="buttons-bottom">
          <div className="button clear" onClick={this.clickExplore}>
            EXPLORE NYA
          </div>
          <div style={{ height: "65px" }} />
          <div className="button" onClick={this.backToPlans}>
            BACK TO PLANS
          </div>
        </div>
      </div>
    );
  }

  clickExplore() {
    document.location.href = "/";
  }

  backToPlans() {
    this.props.router.replace({
      pathname: "/account",
      query: { screen: "plans" },
    });
    this.setState({
      state: "initial",
      annualPlan: "year",
      acceptedTerms: false,
      giftcode: "",
      defaultProration: false,
      codeNotification:""
    });
  }

  render() {
    return (
      <div className="panel plans-panel">
        <div className="title">NYA PLANS</div>
        {this.getContent()}
      </div>
    );
  }
}

PlansPanel.contextTypes = {
  router: PropTypes.object.isRequired,
};
